// datasets: [{
//   label: 'Temperaturverlauf',
//   data: [
//     {
//       x: convertUNIXTimestampToChartJSDateTimeFormat(1665047213599),
//       y: 26
//     },
//     {
//       x: convertUNIXTimestampToChartJSDateTimeFormat(1665050813599),
//       y: 26.049999999999997
//     },
//   ],
//   borderColor: 'rgb(255, 99, 132)',
//   backgroundColor: 'rgba(255, 99, 132, 0.5)',
// }]


function convertUnixTsToChartJSSupportedOne(unixTs){

  var a = new Date(unixTs);
  var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = a.getHours();
  var min = a.getMinutes();
  min = parseInt(min) > 9 ? min : '0'+ min;
  var sec = a.getSeconds();
  // var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
  var time = date + ' ' + month + ' ' + hour + ':' + min;
  // var time = hour + ':' + min + ':';

  return time;
}

function parseData(data){

  const resultArrayOfChartJsObjects = [];
  

  for(let i = 0; i < data.length;i++)
  {
    const chartJsObject = {};
    chartJsObject.x = convertUnixTsToChartJSSupportedOne(data[i].ts);
    chartJsObject.y = data[i].value;
    resultArrayOfChartJsObjects.push(chartJsObject);
  }

  return resultArrayOfChartJsObjects;
}


function convertToChartJSData(hisotricalDataObject){

  let resultData = {};
  try {
    resultData.datasets = [];
    resultData.datasets[0] = {};
    resultData.datasets[0].label = "Temperature";
    resultData.datasets[0].borderColor = "rgb(255, 99, 132)";
    resultData.datasets[0].backgroundColor = "rgba(255, 99, 132, 0.5)";
    resultData.datasets[0].data = parseData(hisotricalDataObject.historical_data.temperature);
    resultData.datasets[0].hidden = false;
       // valid values for tension is 0 to 1
       resultData.datasets[0].tension	 = 0.5;
       resultData.datasets[0].pointRadius= 0;
       resultData.datasets[0].borderWidth= 1;

    resultData.datasets[1] = {};
    resultData.datasets[1].label = "Humidity";
    resultData.datasets[1].borderColor = 'rgb(75, 192, 192)';
    resultData.datasets[1].backgroundColor = "rgba(75, 192, 192, 0.5)";
    resultData.datasets[1].data = parseData(hisotricalDataObject.historical_data.humidity);
    resultData.datasets[1].hidden = false;
   // valid values for tension is 0 to 1
   resultData.datasets[1].tension	 = 0.5;
   resultData.datasets[1].pointRadius= 0;
   resultData.datasets[1].borderWidth= 1;

    resultData.datasets[2] = {};
    resultData.datasets[2].label = "CO2";
    resultData.datasets[2].borderColor = 'rgb(147, 250, 165)';
    resultData.datasets[2].backgroundColor = "rgba((147, 250, 165, 0.5)";
    resultData.datasets[2].data = parseData(hisotricalDataObject.historical_data.co2);
    resultData.datasets[2].hidden = true;
    // valid values for tension is 0 to 1
    resultData.datasets[2].tension	 = 0.5;
    resultData.datasets[2].pointRadius= 0;
    resultData.datasets[2].borderWidth= 1;

   
  } 
  catch (error) {
    throw error;
  }


  return resultData;
}

module.exports = {
  convertToChartJSData,
}