import React, { useState, useEffect } from "react";
// import { useParams } from "react-router";
// import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import axios from "axios";
import './App.css';
import { OS, currentBrowser } from './utils/platform';
import androidLogo from './assets/images/google-play-badge.svg';
import iosLogo from './assets/images/apple-badge.svg';
import { getSensorInformationByQrCode,getSensorHistoricalDataByQrCode } from './services/co2wizardService';
import { convertToChartJSData } from './utils/chartJSDataParser';
// import Donut from "./components/Donut";
import Donut1 from "./components/Donut1";
import LineChart from "./components/Chart";
import { logInfoMessage } from './logging/logger';
import {ResizeObserver as ResizeObserverPolyfill} from '@juggle/resize-observer';
// import { useSwipeable } from 'react-swipeable';
import ReactLoading from "react-loading";

import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

if (typeof window !== 'undefined') {
  window.ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill;
}

// Question: Is the presented value valid or not?
function checkMandatoryParameterAgainstNullOrUndefined(value, errorMessage){
  
  try {

    if( typeof value !== 'undefined' ) 
    {        
            if( value || value === false) 
            {
              return true;    
            }
            else
            {
              return false;
              //              throw new ParameterHasNoValueException(errorMessage);
            }   
    }
    else
    {
      return false;
    }   
  } 
  catch (error) {
    return false;
  }

}

//Get URL-Parameters
//If no URL Parameters are presented forward to 404?

function App() {

  // const [sensorInformation, setSensorInformation] = useState({});
  const [osVersion, setOSVersion] = useState("");
  const [sensorInformation, setSensorInformation] = useState(undefined);
  const [sensorHistoricalData, setSensorHistoricalDataInformation] = useState(undefined);
  const [ip, setIP] = useState('');

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          font: {
            size: '20px',
            family:'vazir'
          }
     }
      },
      responsive:true,
      title: {
        display: true,
        text: 'Historical Data',
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: false,
          },
          pinch: {
            enabled: false
          },
          limits: {
            y: {min: 0, max: 100},
            y2: {min: -5, max: 5}
          },
          // mode: 'x',
        }
      }
    },
  };


  //creating function to load ip address from the API
  const getGeoLocationData = async () => {
    const res = await axios.get('https://geolocation-db.com/json/')
    // console.log(res.data);
    // console.log(ip);

    setIP(res.data.IPv4)
  }


  // const handleSwiped = async (eventData) => {
  //   if (eventData.dir === 'Down') 
  //   {
  //     const sensorId ="";
  //     let response = await getSensorInformationByQrCodeDEMO(sensorId);          
  //     setSensorInformation(response);
  //   } 
  //   else {
     
  //   }
  // };
  // const handlers = useSwipeable({
  //   onSwiped: handleSwiped,
  //   trackMouse: true
  // });

  useEffect(() => {    
    let {qrcode} = queryString.parse(window.location.search); 
    // console.log('Trying to load info for: '+qrcode);
    // getGeoLocationData();
    logInfoMessage(OS(window));
    logInfoMessage(window.navigator.appVersion);
    setOSVersion(OS(window));
    // console.log(currentBrowser(window));

    async function fetchSensorInformation(qrcodeContent) {
      try 
      {              
          // console.log('Trying to load info for: '+qrcode);
          let response = await getSensorInformationByQrCode(qrcodeContent);
          let response2 = await getSensorHistoricalDataByQrCode(qrcodeContent);
          setSensorInformation(response);
          setSensorHistoricalDataInformation(response2);
          
      } 
      catch (error) 
      {
          setSensorInformation(undefined);
          // console.log(error);
      }
    }

    fetchSensorInformation(qrcode);  

  }, []);

  
  //Todo: Return Loading screen instead of nothing to prevent empty page
  // User does not know that anything is happening
  if (sensorInformation === undefined) return  <div style={{ 
    width:'100vw',
    height:'100vh',
    backgroundColor: '#282c34',
    display: 'flex',
    justifyContent: 'center'
    }}>
      <div style={{ 
        // display: 'table',
        // width: '50%',
        // margin: 'auto',
        marginTop: '30%'
    }}>
    <ReactLoading   
      type={"bars"}
      color={"#03fc4e"}
      height={100}
      width={100}  
  />
  </div>
  </div>
  if (sensorHistoricalData === undefined) return  <ReactLoading
    type={"bars"}
    color={"#03fc4e"}
    height={100}
    width={100}
    />


  return (
    <div className="App">
      {/* <div {...handlers}> */}
      <header className="App-header">     
      {sensorInformation !== undefined ?
        <div className="room-info">       
          <h4>{sensorInformation.room}</h4>
        </div>
         :''
        }
       { 
        checkMandatoryParameterAgainstNullOrUndefined(sensorInformation.current_co2_value) === true ?
        <>
        <Slider
          dots={true}
          infinite={true}
          arrow={true}
          speed={500}
          slidesToShow={1}
          slidesToScroll={1}
          cssEase='linear'
          style={{
            width: "250px",
            marginLeft:"20px",
            marginRight:"20px"
        
        }}
        >
          <div key={0}> 
          <Donut1 
            style={{
              postition: "relative",

            }}
            label="CO2"
            unit="ppm"
            key={sensorInformation.current_co2_value}
            value={sensorInformation.current_co2_value}
            co2ThresholdValue={1500}
            thresholdYellow={1000}
            thresholdRed={1500}
            />
          </div>
          <div key={1}> 
            <Donut1 
              label="Humidity"
              unit="%"
              key={sensorInformation.current_humidity_value}
              value={sensorInformation.current_humidity_value}
              co2ThresholdValue={80}
              thresholdYellow={60}
              thresholdRed={80}
              />
          </div>
          <div key={2}> 
          <Donut1 
            label="Temperature"
            unit="°C"
            key={sensorInformation.current_temperature_value}
            value={sensorInformation.current_temperature_value}
            co2ThresholdValue={22.5}
            thresholdYellow={20}
            thresholdRed={22.5}
            />
          </div>
        </Slider>
       

        
        
       
        </>
        
        
        :
        <div>
          <p>waiting for value...</p>
        </div>
       }

 

<div style={{marginTop:'30px'}} >
        <p>Hol dir jetzt den CO<sub>2</sub>Wizard!</p>
        {/* <p>{osVersion}</p> */}
        {/* Load the right App-Store Icon based on the used User Device (iOS or Android) */}
          {
            osVersion === "MacOS" ?
            <a
          className="App-link"
          href="https://apps.apple.com/us/app/co2wizard/id1568479831?mt=8"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={iosLogo} style={{ width:'150px'}} alt="" /> 
        </a>  
                 
          :
          <a
          className="App-link"
          href="https://play.google.com/store/apps/details?id=at.community.iot.co2wizard"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={androidLogo} style={{ width:'150px'}} alt="" /> 
        </a> 
          }
       </div>

      </header>
      {/* <div className="chart"> */}
      <LineChart
       options={options}
       data={convertToChartJSData(sensorHistoricalData)}></LineChart>
      
      <div style={{
        height: '200px',
        backgroundColor: "#282c34"}}>

       </div>
      </div>
      
  );
}

export default App;
