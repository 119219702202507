import React, {useRef} from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import {faker} from '@faker-js/faker';

// import styles from '../styles/global.scss';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  zoomPlugin,
  Title,
  Tooltip,
  Legend
);


export default function LineChart(props) {
  // return (  
  //   <> 
  //  <div   style={{width:'250px'}}>
  //       <Line height={"100vh"} options={props.options} data={props.data} />
  //  </div>
    
  //   </>
  //   );

    function toogleChart () {
     
      // lets go
      const chartBox = document.querySelector('.chartBox');
      const labelOfButton = document.getElementById('toggleSizeButton');


      if(labelOfButton.innerHTML === "Fullscreen")
      {
        //Change label to something else
        labelOfButton.innerHTML = "Minimize";
      }
      else
      {
        labelOfButton.innerHTML = "Fullscreen";
      }

      if(chartBox!== null)
      {
        chartBox.classList.toggle('fullScreen');
      }      
      

   
    }

    return (  
        <>
         
       <div className='chartBox' 
      //  style={{overflowY: "scroll"}}
       >
         {/* <button
          id="toggleSizeButton" onClick={() => {
             console.log("button clicked");
             toogleChart();
             }}>Fullscreen</button> */}
          <div className='holder'>
            <Line        
 
            id="lineChart"
            options={props.options} 
            data={props.data} 
      
            />
            </div>
          </div>
        </>
      );
  
}
