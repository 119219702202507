import React, { useState,useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';

const green = 'rgba(68, 178, 75, 1)';
const orange = 'rgba(255, 156, 6, 1)';
const red = 'rgba(255, 66, 45, 1)';

function calcColor(co2Value,thresholdYellow,thresholdRed){

    if(co2Value >= thresholdRed)
    {
        return red;
    }
    else if(co2Value >= thresholdYellow)
    {
        return orange;
    }

    return green;
}

// const calcPluginData = (co2Value) => {
function calcPluginData(valueToSet, unitToSet){
    // console.log('## calculate plugin with value: ' + co2Value);
    let testVal = valueToSet;
    // console.log('## calculate plugin with testVal: ' + testVal);
    return [{
      id: 'text',      
      beforeDraw: (chart) => 
      {
        var width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
  
        ctx.restore();
        var fontSize = (height / 114).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "middle";    
       
        var text = testVal,
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2;
  
        // For the placement of the Value  
        ctx.fillText(text, textX, textY);                 
        ctx.fillStyle = "#ffffff";
        // For the placement of the Einheit
        ctx.font = "20px Arial";
        
        var textUnit = unitToSet,
        textUnitX = Math.round((width - ctx.measureText(text).width) / 2),
        textUnitY = height / 2; 
        ctx.fillText(textUnit, textUnitX, textUnitY+35); 
        ctx.textBaseline = "bottom";  
       
  
        ctx.save();
      }
    }];
  }

function Donut(props) {    

    const options = {

        cutout: '96%',
        legend: {
        display: false
          },     
    }
    
    const [value, setValue] = useState(props.value ? props.value : 0);
    const [thresholdYellow, setValueThresholdYellow] = useState(props.thresholdYellow ? props.thresholdYellow : 0);
    const [thresholdRed, setValueThresholdRed] = useState(props.thresholdRed ? props.thresholdRed : 0);
    const [unit, setUnit] = useState(props.unit ? props.unit : "undefined");
    const [pluginData, setpluginData] = useState([]);

    useEffect(() => {
        // console.log('INITIAL state value: ' + co2Value);
        // console.log('props value: ' + props.co2Value);
        setValue(props.value); 
        setUnit(props.unit); 
        setValueThresholdYellow(props.thresholdYellow);
        setValueThresholdRed(props.thresholdRed);
        // console.log('useEffect setting co2val to: ' + props.co2Value);
        let plugins = calcPluginData(props.value,props.unit);
        setpluginData(plugins);        
        // setco2Value(props.co2Value.toString());
   
      // }, [co2Value !== props.co2Value]);
      }, [value,props.value]);


    const data = {
        datasets: [
          {
            label: props.label,
            // data: [ co2Value.co2Value, props.co2ThresholdValue],      
            data: [ value, props.co2ThresholdValue - value > 0 ?  props.co2ThresholdValue - value : 0],      
            backgroundColor: [               
               calcColor(value,thresholdYellow,thresholdRed),
              'rgba(153, 157, 154, 1)',        
            ],    
             borderWidth: 0,
             weight: 3,
          },
        ],
      };

    

    return (  
    <>
        <div className='header'>     
        <div className='links'>{props.label}    
        </div>
        </div>
        <div   style={{width:'250px'}}>
            { 
            pluginData.length > 0 ?
                <div>
                <Doughnut type="doughnut" data={data} options={options} plugins={calcPluginData(value,unit)} />                
                </div>
                :'waiting'
            }
        </div>
        
    </>
    );
}

export default Donut;