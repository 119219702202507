const axios = require("axios");
const { DateTime } = require('luxon');
const { REACT_APP_ELASTICSEARCH_URL } = process.env;


const getUserLocationData = async () => {
  const res = await axios.get('https://geolocation-db.com/json/')
  console.log(res.data);
  return res.data;
}

export default async function logInformationToElasticsearch(index,logMessage, messageType, messageStatusCode){
  
    let response ={};
    let indexName = index;
    indexName = 'securityinfo';
    let url =`${REACT_APP_ELASTICSEARCH_URL}/${indexName}/_doc`; 
    console.log(url);
    url = "https://elasticsearch.benchvondaranch.com/securityinfo/_doc";   
    console.log(url);

    const config = {
      crossdomain: true,
      headers: {
          'Content-Type': 'application/json'
      }
    };

    //get current local time and add it to the request
    let currentDate = DateTime.local();
    currentDate.setZone('Europe/Vienna');
    currentDate.setLocale('de-AT');
    const currentISODate = currentDate.toISO();


    const userLocationInformation = await getUserLocationData();
    //get current ip address of the user and add it to the request

    const bodyParameters =  {
      "@timestamp": `${currentISODate}`,
      currentdate: `${currentISODate}`,
      message : `${logMessage}`,
      message_type: `${messageType}`,
      message_status_code:`${messageStatusCode}`,
      location: { 
        lat: 41.12,
        lon: -71.34
      },
      user_destination :
      {
          ip : `${userLocationInformation.IPv4}`,
          port: 88,
          country_code: `${userLocationInformation.country_code}`,
          country_name: `${userLocationInformation.country_name}`,
          latitude: `${userLocationInformation.latitude}`,
          longitude: `${userLocationInformation.longitude}`,
          postal: `${userLocationInformation.postal}`,
          state: `${userLocationInformation.state}`
      },
      geo_location : {
        type:"FeatureCollection",
        features:[
           {
              type:"Feature",
              geometry:{
                 type:"geo_point",
                 coordinates:[
                    15.4,
                    47
                 ]
              },
              properties:{
                 "prop0":"value0"
              }
           }
        ]
     }
   }

    try {     
     
      //const resultOfCO2WizardAPICall = await axios.post(url, bodyParameters, config);
      const res = await axios.post(url, bodyParameters,config);
  
      response = res;

  
      return response;
    } 
    catch (error) {
    //   logErrorMessage(error.message);
      console.log(error.response.data);
      throw error;
    }
}


export { 
  logInformationToElasticsearch,
}