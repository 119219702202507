const axios = require('axios');
// const {  logInfoMessage,  logWarningMessage,  logErrorMessage,  logSuccessMessage} = require("./../logging/logger");
// const { checkAgainstNullOrUndefined,checkMandatoryParameterAgainstNullOrUndefined } = require("../utils/validationHandler");
const { REACT_APP_BACKEND_URL } = process.env;
const { REACT_APP_BACKEND_API_KEY } = process.env;

function getCurrentDateTimeAsUnixTimeStamp() {
  const dateInUNIXMilis = Date.now();

  return dateInUNIXMilis;
}
function getse() {
  //3600000 = 1 Stunde in Millisekunden * X Stunden
  // * 120 = die letzten 5 Tage
  const dateInUNIXMilis = Date.now();
  const B = 3600000 * 120;
  const test = dateInUNIXMilis - B;

  return test;
}

function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export async function getSensorInformationByQrCode(qrcodeContent) {
  if (qrcodeContent) {
    let response = {};
    const url = REACT_APP_BACKEND_URL + '/iot_device/get_info';
    // console.log(url);

    const config = {
      headers: {
        Authorization: `${REACT_APP_BACKEND_API_KEY}`,
      },
    };

    const bodyParameters = {
      qr_code_content: `${qrcodeContent}`,
    };

    try {
      // Step 1: Get the data from datacake - example see dummyReturnValue
      const resultOfCO2WizardAPICall = await axios.post(
        url,
        bodyParameters,
        config
      );
      // console.log(resultOfCO2WizardAPICall);
      response = {
        status_code: resultOfCO2WizardAPICall.data.status_code,
        device_id: resultOfCO2WizardAPICall.data.device_id,
        dashboard_url: resultOfCO2WizardAPICall.data.dashboard_url,
        location: resultOfCO2WizardAPICall.data.location,
        room: resultOfCO2WizardAPICall.data.room,
        current_co2_value: resultOfCO2WizardAPICall.data.current_co2_value,
        current_humidity_value:
          resultOfCO2WizardAPICall.data.current_humidity_value,
        current_temperature_value:
          Math.round(
            resultOfCO2WizardAPICall.data.current_temperature_value * 100
          ) / 100,
      };
      return response;
    } catch (error) {
      //   logErrorMessage(error.message);
      throw error;
    }
  } else {
    return await getSensorInformationByQrCodeDEMO();
  }
}

export async function getSensorHistoricalDataByQrCode(qrcodeContent) {
  if (qrcodeContent) {
    let response = {};
    const url = REACT_APP_BACKEND_URL + '/iot_device/get_historical_data';
    // console.log(url);

    const config = {
      headers: {
        Authorization: `${REACT_APP_BACKEND_API_KEY}`,
      },
    };

    const bodyParameters = {
      qr_code_content: `${qrcodeContent}`,
      wanted_measurements: ['temperature', 'humidity', 'co2'],
      start_ts: getse(),
      end_ts: getCurrentDateTimeAsUnixTimeStamp(),
    };

    try {
      // Step 1: Get the data from datacake - example see dummyReturnValue
      const resultOfCO2WizardAPICall = await axios.post(
        url,
        bodyParameters,
        config
      );
      // console.log(resultOfCO2WizardAPICall);
      response = {
        status_code: resultOfCO2WizardAPICall.data.status_code,
        device_id: resultOfCO2WizardAPICall.data.device_id,
        dashboard_url: resultOfCO2WizardAPICall.data.dashboard_url,
        location: resultOfCO2WizardAPICall.data.location,
        room: resultOfCO2WizardAPICall.data.room,
        historical_data: resultOfCO2WizardAPICall.data.historical_data,
      };

      return response;
    } catch (error) {
      //   logErrorMessage(error.message);
      throw error;
    }
  } else {
    return await getSensorInformationByQrCodeDEMO();
  }
}

export async function getSensorInformationByQrCodeDEMO() {
  try {
    let response = {};
    // Step 1: Get the data from datacake - example see dummyReturnValue
    const rndInt = randomIntFromInterval(400, 2500);
    response = {
      status_code: '200',
      device_id: 'Demo Raum',
      dashboard_url: 'Dashboard URL',
      location: 'Demo Location',
      room: 'Demo Raum',
      current_co2_value: rndInt,
      current_temperature_value: 21,
      current_humidity_value: 42,
      historical_data: {
        temperature: [
          { ts: 1669369709481, value: 24.700000000000003 },
          { ts: 1669373309481, value: 24.799999999999997 },
          { ts: 1669376909481, value: 24.900000000000006 },
          { ts: 1669380509481, value: 24.900000000000006 },
          { ts: 1669384109481, value: 24.900000000000006 },
          { ts: 1669387709481, value: 24.900000000000006 },
          { ts: 1669391309481, value: 24.900000000000006 },
          { ts: 1669394909481, value: 24.799999999999997 },
          { ts: 1669398509481, value: 24.200000000000003 },
          { ts: 1669402109481, value: 23.799999999999997 },
          { ts: 1669405709481, value: 23.5 },
          { ts: 1669409309481, value: 23.299999999999997 },
          { ts: 1669412909481, value: 23.200000000000003 },
          { ts: 1669416509481, value: 23.099999999999994 },
          { ts: 1669420109481, value: 23.099999999999994 },
          { ts: 1669423709481, value: 23 },
          { ts: 1669427309481, value: 22.900000000000006 },
          { ts: 1669430909481, value: 22.900000000000006 },
          { ts: 1669434509481, value: 22.900000000000006 },
          { ts: 1669438109481, value: 22.799999999999997 },
          { ts: 1669441709481, value: 22.799999999999997 },
          { ts: 1669445309481, value: 23.5 },
          { ts: 1669448909481, value: 24.099999999999994 },
          { ts: 1669452509481, value: 24.400000000000006 },
          { ts: 1669456109481, value: 24.599999999999994 },
          { ts: 1669459709481, value: 24.5 },
          { ts: 1669463309481, value: 24 },
          { ts: 1669466909481, value: 23.599999999999994 },
          { ts: 1669470509481, value: 23.299999999999997 },
          { ts: 1669474109481, value: 23.099999999999994 },
          { ts: 1669477709481, value: 23 },
          { ts: 1669481309481, value: 22.900000000000006 },
          { ts: 1669484909481, value: 22.799999999999997 },
          { ts: 1669488509481, value: 22.799999999999997 },
          { ts: 1669492109481, value: 22.799999999999997 },
          { ts: 1669495709481, value: 22.799999999999997 },
          { ts: 1669499309481, value: 22.799999999999997 },
          { ts: 1669502909481, value: 22.799999999999997 },
          { ts: 1669506509481, value: 22.700000000000003 },
          { ts: 1669510109481, value: 22.799999999999997 },
          { ts: 1669513709481, value: 22.700000000000003 },
          { ts: 1669517309481, value: 22.700000000000003 },
          { ts: 1669520909481, value: 22.799999999999997 },
          { ts: 1669524509481, value: 22.700000000000003 },
          { ts: 1669528109481, value: 22.700000000000003 },
          { ts: 1669531709481, value: 23.5 },
          { ts: 1669535309481, value: 23.900000000000006 },
          { ts: 1669538909481, value: 24.299999999999997 },
          { ts: 1669542509481, value: 24.5 },
          { ts: 1669546109481, value: 24.400000000000006 },
          { ts: 1669549709481, value: 23.900000000000006 },
          { ts: 1669553309481, value: 23.5 },
          { ts: 1669556909481, value: 23.400000000000006 },
          { ts: 1669560509481, value: 23.200000000000003 },
          { ts: 1669564109481, value: 23.099999999999994 },
          { ts: 1669567709481, value: 22.900000000000006 },
          { ts: 1669571309481, value: 22.799999999999997 },
          { ts: 1669574909481, value: 22.799999999999997 },
          { ts: 1669578509481, value: 22.799999999999997 },
          { ts: 1669582109481, value: 22.799999999999997 },
          { ts: 1669585709481, value: 22.799999999999997 },
          { ts: 1669589309481, value: 22.799999999999997 },
          { ts: 1669592909481, value: 22.799999999999997 },
          { ts: 1669596509481, value: 22.799999999999997 },
          { ts: 1669600109481, value: 22.799999999999997 },
          { ts: 1669603709481, value: 22.799999999999997 },
          { ts: 1669607309481, value: 22.700000000000003 },
          { ts: 1669610909481, value: 22.700000000000003 },
          { ts: 1669614509481, value: 23.5 },
          { ts: 1669618109481, value: 24 },
          { ts: 1669621709481, value: 24.099999999999994 },
          { ts: 1669625309481, value: 24.200000000000003 },
          { ts: 1669628909481, value: 24.5 },
          { ts: 1669632509481, value: 24.700000000000003 },
          { ts: 1669636109481, value: 25.299999999999997 },
          { ts: 1669639709481, value: 25.299999999999997 },
          { ts: 1669643309481, value: 25.299999999999997 },
          { ts: 1669646909481, value: 25.200000000000003 },
          { ts: 1669650509481, value: 25.099999999999994 },
          { ts: 1669654109481, value: 24.900000000000006 },
          { ts: 1669657709481, value: 24.299999999999997 },
          { ts: 1669661309481, value: 23.799999999999997 },
          { ts: 1669664909481, value: 23.5 },
          { ts: 1669668509481, value: 23.299999999999997 },
          { ts: 1669672109481, value: 23.200000000000003 },
          { ts: 1669675709481, value: 23.099999999999994 },
          { ts: 1669679309481, value: 23 },
          { ts: 1669682909481, value: 22.900000000000006 },
          { ts: 1669686509481, value: 23 },
          { ts: 1669690109481, value: 22.900000000000006 },
          { ts: 1669693709481, value: 22.900000000000006 },
          { ts: 1669697309481, value: 22.799999999999997 },
          { ts: 1669700909481, value: 23.599999999999994 },
          { ts: 1669704509481, value: 24.099999999999994 },
          { ts: 1669708109481, value: 24.400000000000006 },
          { ts: 1669711709481, value: 24.799999999999997 },
          { ts: 1669715309481, value: 24.799999999999997 },
          { ts: 1669718909481, value: 24.599999999999994 },
          { ts: 1669722509481, value: 24.400000000000006 },
          { ts: 1669726109481, value: 24.299999999999997 },
          { ts: 1669729709481, value: 24.400000000000006 },
          { ts: 1669733309481, value: 24.5 },
          { ts: 1669736909481, value: 24.099999999999994 },
          { ts: 1669740509481, value: 23.799999999999997 },
          { ts: 1669744109481, value: 23.299999999999997 },
          { ts: 1669747709481, value: 23.200000000000003 },
          { ts: 1669751309481, value: 23 },
          { ts: 1669754909481, value: 22.799999999999997 },
          { ts: 1669758509481, value: 22.799999999999997 },
          { ts: 1669762109481, value: 22.799999999999997 },
          { ts: 1669765709481, value: 22.799999999999997 },
          { ts: 1669769309481, value: 22.700000000000003 },
          { ts: 1669772909481, value: 22.700000000000003 },
          { ts: 1669776509481, value: 22.700000000000003 },
          { ts: 1669780109481, value: 22.599999999999994 },
          { ts: 1669783709481, value: 22.599999999999994 },
          { ts: 1669787309481, value: 23.099999999999994 },
          { ts: 1669790909481, value: 23.099999999999994 },
          { ts: 1669794509481, value: 23.299999999999997 },
          { ts: 1669798109481, value: 23.400000000000006 },
        ],
        humidity: [
          { ts: 1669369709481, value: 35 },
          { ts: 1669373309481, value: 35 },
          { ts: 1669376909481, value: 34 },
          { ts: 1669380509481, value: 34 },
          { ts: 1669384109481, value: 34 },
          { ts: 1669387709481, value: 35 },
          { ts: 1669391309481, value: 34 },
          { ts: 1669394909481, value: 34 },
          { ts: 1669398509481, value: 35 },
          { ts: 1669402109481, value: 35 },
          { ts: 1669405709481, value: 35 },
          { ts: 1669409309481, value: 36 },
          { ts: 1669412909481, value: 36 },
          { ts: 1669416509481, value: 36 },
          { ts: 1669420109481, value: 36 },
          { ts: 1669423709481, value: 36 },
          { ts: 1669427309481, value: 36 },
          { ts: 1669430909481, value: 36 },
          { ts: 1669434509481, value: 36 },
          { ts: 1669438109481, value: 36 },
          { ts: 1669441709481, value: 36 },
          { ts: 1669445309481, value: 36 },
          { ts: 1669448909481, value: 35 },
          { ts: 1669452509481, value: 34 },
          { ts: 1669456109481, value: 34 },
          { ts: 1669459709481, value: 34 },
          { ts: 1669463309481, value: 35 },
          { ts: 1669466909481, value: 35 },
          { ts: 1669470509481, value: 35 },
          { ts: 1669474109481, value: 35 },
          { ts: 1669477709481, value: 36 },
          { ts: 1669481309481, value: 36 },
          { ts: 1669484909481, value: 36 },
          { ts: 1669488509481, value: 36 },
          { ts: 1669492109481, value: 36 },
          { ts: 1669495709481, value: 36 },
          { ts: 1669499309481, value: 36 },
          { ts: 1669502909481, value: 36 },
          { ts: 1669506509481, value: 36 },
          { ts: 1669510109481, value: 36 },
          { ts: 1669513709481, value: 36 },
          { ts: 1669517309481, value: 36 },
          { ts: 1669520909481, value: 36 },
          { ts: 1669524509481, value: 36 },
          { ts: 1669528109481, value: 36 },
          { ts: 1669531709481, value: 36 },
          { ts: 1669535309481, value: 35 },
          { ts: 1669538909481, value: 34 },
          { ts: 1669542509481, value: 34 },
          { ts: 1669546109481, value: 34 },
          { ts: 1669549709481, value: 34 },
          { ts: 1669553309481, value: 34 },
          { ts: 1669556909481, value: 35 },
          { ts: 1669560509481, value: 35 },
          { ts: 1669564109481, value: 35 },
          { ts: 1669567709481, value: 35 },
          { ts: 1669571309481, value: 35 },
          { ts: 1669574909481, value: 35 },
          { ts: 1669578509481, value: 35 },
          { ts: 1669582109481, value: 35 },
          { ts: 1669585709481, value: 35 },
          { ts: 1669589309481, value: 35 },
          { ts: 1669592909481, value: 35 },
          { ts: 1669596509481, value: 35 },
          { ts: 1669600109481, value: 35 },
          { ts: 1669603709481, value: 35 },
          { ts: 1669607309481, value: 35 },
          { ts: 1669610909481, value: 35 },
          { ts: 1669614509481, value: 35 },
          { ts: 1669618109481, value: 34 },
          { ts: 1669621709481, value: 33 },
          { ts: 1669625309481, value: 27 },
          { ts: 1669628909481, value: 29 },
          { ts: 1669632509481, value: 29 },
          { ts: 1669636109481, value: 29 },
          { ts: 1669639709481, value: 30 },
          { ts: 1669643309481, value: 30 },
          { ts: 1669646909481, value: 30 },
          { ts: 1669650509481, value: 30 },
          { ts: 1669654109481, value: 31 },
          { ts: 1669657709481, value: 31 },
          { ts: 1669661309481, value: 32 },
          { ts: 1669664909481, value: 32 },
          { ts: 1669668509481, value: 32 },
          { ts: 1669672109481, value: 32 },
          { ts: 1669675709481, value: 33 },
          { ts: 1669679309481, value: 33 },
          { ts: 1669682909481, value: 33 },
          { ts: 1669686509481, value: 33 },
          { ts: 1669690109481, value: 33 },
          { ts: 1669693709481, value: 33 },
          { ts: 1669697309481, value: 33 },
          { ts: 1669700909481, value: 33 },
          { ts: 1669704509481, value: 32 },
          { ts: 1669708109481, value: 32 },
          { ts: 1669711709481, value: 33 },
          { ts: 1669715309481, value: 32 },
          { ts: 1669718909481, value: 32 },
          { ts: 1669722509481, value: 31 },
          { ts: 1669726109481, value: 32 },
          { ts: 1669729709481, value: 32 },
          { ts: 1669733309481, value: 32 },
          { ts: 1669736909481, value: 32 },
          { ts: 1669740509481, value: 33 },
          { ts: 1669744109481, value: 33 },
          { ts: 1669747709481, value: 33 },
          { ts: 1669751309481, value: 33 },
          { ts: 1669754909481, value: 33 },
          { ts: 1669758509481, value: 33 },
          { ts: 1669762109481, value: 33 },
          { ts: 1669765709481, value: 33 },
          { ts: 1669769309481, value: 33 },
          { ts: 1669772909481, value: 33 },
          { ts: 1669776509481, value: 33 },
          { ts: 1669780109481, value: 33 },
          { ts: 1669783709481, value: 33 },
          { ts: 1669787309481, value: 33 },
          { ts: 1669790909481, value: 33 },
          { ts: 1669794509481, value: 32 },
          { ts: 1669798109481, value: 32 },
        ],
        co2: [
          { ts: 1669369709481, value: 703 },
          { ts: 1669373309481, value: 743 },
          { ts: 1669376909481, value: 779 },
          { ts: 1669380509481, value: 796 },
          { ts: 1669384109481, value: 768 },
          { ts: 1669387709481, value: 797 },
          { ts: 1669391309481, value: 745 },
          { ts: 1669394909481, value: 742 },
          { ts: 1669398509481, value: 705 },
          { ts: 1669402109481, value: 703 },
          { ts: 1669405709481, value: 681 },
          { ts: 1669409309481, value: 650 },
          { ts: 1669412909481, value: 646 },
          { ts: 1669416509481, value: 617 },
          { ts: 1669420109481, value: 617 },
          { ts: 1669423709481, value: 614 },
          { ts: 1669427309481, value: 591 },
          { ts: 1669430909481, value: 590 },
          { ts: 1669434509481, value: 602 },
          { ts: 1669438109481, value: 549 },
          { ts: 1669441709481, value: 575 },
          { ts: 1669445309481, value: 535 },
          { ts: 1669448909481, value: 556 },
          { ts: 1669452509481, value: 584 },
          { ts: 1669456109481, value: 581 },
          { ts: 1669459709481, value: 615 },
          { ts: 1669463309481, value: 645 },
          { ts: 1669466909481, value: 681 },
          { ts: 1669470509481, value: 696 },
          { ts: 1669474109481, value: 721 },
          { ts: 1669477709481, value: 670 },
          { ts: 1669481309481, value: 682 },
          { ts: 1669484909481, value: 651 },
          { ts: 1669488509481, value: 649 },
          { ts: 1669492109481, value: 610 },
          { ts: 1669495709481, value: 592 },
          { ts: 1669499309481, value: 582 },
          { ts: 1669502909481, value: 580 },
          { ts: 1669506509481, value: 569 },
          { ts: 1669510109481, value: 565 },
          { ts: 1669513709481, value: 568 },
          { ts: 1669517309481, value: 560 },
          { ts: 1669520909481, value: 531 },
          { ts: 1669524509481, value: 532 },
          { ts: 1669528109481, value: 527 },
          { ts: 1669531709481, value: 524 },
          { ts: 1669535309481, value: 514 },
          { ts: 1669538909481, value: 498 },
          { ts: 1669542509481, value: 516 },
          { ts: 1669546109481, value: 491 },
          { ts: 1669549709481, value: 496 },
          { ts: 1669553309481, value: 523 },
          { ts: 1669556909481, value: 505 },
          { ts: 1669560509481, value: 488 },
          { ts: 1669564109481, value: 500 },
          { ts: 1669567709481, value: 498 },
          { ts: 1669571309481, value: 503 },
          { ts: 1669574909481, value: 499 },
          { ts: 1669578509481, value: 515 },
          { ts: 1669582109481, value: 488 },
          { ts: 1669585709481, value: 497 },
          { ts: 1669589309481, value: 504 },
          { ts: 1669592909481, value: 487 },
          { ts: 1669596509481, value: 478 },
          { ts: 1669600109481, value: 481 },
          { ts: 1669603709481, value: 488 },
          { ts: 1669607309481, value: 477 },
          { ts: 1669610909481, value: 480 },
          { ts: 1669614509481, value: 481 },
          { ts: 1669618109481, value: 480 },
          { ts: 1669621709481, value: 1100 },
          { ts: 1669625309481, value: 710 },
          { ts: 1669628909481, value: 787 },
          { ts: 1669632509481, value: 812 },
          { ts: 1669636109481, value: 793 },
          { ts: 1669639709481, value: 930 },
          { ts: 1669643309481, value: 761 },
          { ts: 1669646909481, value: 685 },
          { ts: 1669650509481, value: 687 },
          { ts: 1669654109481, value: 671 },
          { ts: 1669657709481, value: 625 },
          { ts: 1669661309481, value: 591 },
          { ts: 1669664909481, value: 569 },
          { ts: 1669668509481, value: 586 },
          { ts: 1669672109481, value: 562 },
          { ts: 1669675709481, value: 563 },
          { ts: 1669679309481, value: 567 },
          { ts: 1669682909481, value: 525 },
          { ts: 1669686509481, value: 532 },
          { ts: 1669690109481, value: 545 },
          { ts: 1669693709481, value: 508 },
          { ts: 1669697309481, value: 540 },
          { ts: 1669700909481, value: 511 },
          { ts: 1669704509481, value: 510 },
          { ts: 1669708109481, value: 506 },
          { ts: 1669711709481, value: 703 },
          { ts: 1669715309481, value: 658 },
          { ts: 1669718909481, value: 750 },
          { ts: 1669722509481, value: 686 },
          { ts: 1669726109481, value: 723 },
          { ts: 1669729709481, value: 796 },
          { ts: 1669733309481, value: 732 },
          { ts: 1669736909481, value: 717 },
          { ts: 1669740509481, value: 697 },
          { ts: 1669744109481, value: 681 },
          { ts: 1669747709481, value: 678 },
          { ts: 1669751309481, value: 653 },
          { ts: 1669754909481, value: 614 },
          { ts: 1669758509481, value: 608 },
          { ts: 1669762109481, value: 588 },
          { ts: 1669765709481, value: 581 },
          { ts: 1669769309481, value: 563 },
          { ts: 1669772909481, value: 553 },
          { ts: 1669776509481, value: 547 },
          { ts: 1669780109481, value: 531 },
          { ts: 1669783709481, value: 536 },
          { ts: 1669787309481, value: 519 },
          { ts: 1669790909481, value: 529 },
          { ts: 1669794509481, value: 540 },
          { ts: 1669798109481, value: 713 },
        ],
      },
    };
    return response;
  } catch (error) {
    throw error;
  }
}

// export default {
//     getCurrentSensorInformation,
// }
